<template>
  <div class="login-page">
    <div class="login-header">
      <img class="az-logo" src="@/assets/img/AstraZeneca.png" />
      <img class="sb-logo" src="@/assets/img/simplybrand-logo.png" />
    </div>
    <div class="login-form">
      <div class="login-form-title">
        <h1>AstraZeneca Media Monitoring Dashboard</h1>
      </div>
      <div class="login-form-body">
        <h2>登录</h2>
        <el-form ref="form" :model="login">
          <el-form-item>
            <el-input v-model="login.user" placeholder="账号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="login.pass" placeholder="密码" show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-checkbox v-model="isRemenber" label="记住密码" name="type"></el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="login" @click="onLogin()">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      login: {
        user: '',
        pass: ''
      },
      isRemenber: false
    }
  },

  mounted(){
    this.getUsersPass()
  },

  methods: {
    /**
     * 登录
     */
    onLogin: function(){
      let that = this
      if(that.login.user == ''){
        that.$message({
          type: 'error',
          message: '用户名不能为空',
          duration: 2000
        })
        return
      } 
      if(that.login.pass == ''){
        that.$message({
          type: 'error',
          message: '密码不能为空',
          duration: 2000
        })
        return
      }
      let userinfo = {
        email: that.login.user,
        password: that.login.pass
      }
      that.$axios.post(that.API_URL + '/Login', userinfo)
      .then(function (response) {
        that.setLocalStorage('token', response.data.token)
        if (that.isRemenber === true) {
          that.setLocalStorage( "isremenber", true )
          that.setLocalStorage( "loginemail", that.login.user )
          that.setLocalStorage( "loginpasswd", that.login.pass )
        }
        if (that.isRemenber === false) {
          that.setLocalStorage( "isremenber", false )
          window.localStorage.removeItem('loginemail')
          window.localStorage.removeItem('loginpasswd')
        }  
        that.$router.push({ path: "/" })
      })
      .catch(function (error) {
        console.log(error)
        that.$message({
          type: 'error',
          message: '用户名或密码错误',
          duration: 2000
        })
        return
      })

    },

    /**
     * 获取用户保存的用户名和密码
     */
    getUsersPass: function(){
      if(localStorage.getItem("loginemail")&&localStorage.getItem("loginpasswd")){
        this.isRemenber = JSON.parse(localStorage.getItem("isremenber")).val
        this.login.user = JSON.parse(localStorage.getItem("loginemail")).val
        this.login.pass = JSON.parse(localStorage.getItem("loginpasswd")).val
      }
    },
  }
}
</script>